import styles from "@/components/layouts/AuthLayout.module.scss";
import { getTheme } from "@/features/branding";
import { ReactNode } from "react";
export interface AuthLayoutProps {
  children: ReactNode;
  formWidth?: string;
}
export const AuthLayout = ({
  children,
  formWidth = "450px"
}: AuthLayoutProps) => {
  const {
    meta,
    antd
  } = getTheme();
  const bgImage = meta.backgroundImage;
  const bgColor = meta.backgroundColor || antd.token?.colorPrimary;
  return <div className={styles.container} style={{
    backgroundImage: bgImage ? `url(${bgImage})` : undefined,
    backgroundColor: bgColor
  }} data-sentry-component="AuthLayout" data-sentry-source-file="AuthLayout.tsx">
      <div className={styles.formContainer} style={{
      width: formWidth
    }}>
        {children}
      </div>
    </div>;
};
export default AuthLayout;